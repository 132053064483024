import { loadComponent } from 'saddlebag-componentloader';
import observer from 'saddlebag-observer';
import logger from 'saddlebag-logger';
import { GLOBAL } from 'saddlebag-browser';

import type { LoadComponentParams } from './types';

type LoginButtonClickedEvent = {
  loginModalVersion: string;
  locale: string;
  market: string;
  azureActiveWeb: boolean;
  azureLogoActiveWeb: boolean;
  authConsumer: string;
};

type LoginFormParams = {
  locale: string;
  market: string;
};

const $window = GLOBAL.getWindow();
const $document = GLOBAL.getDocument();
const namespace = 'header-component';

let loginModalLoaded = false;
const IS_PRODUCTION = process.env.NODE_ENV === 'production';
const IS_TEST = $window.location.hostname === 'localhost';

const loadLoginModal = async (version: string, params: LoginFormParams) => {
  const rootElement = $document.createElement('div');
  $document.body.appendChild(rootElement);
  const loadComponentParams: LoadComponentParams = {
    name: 'login-form',
    version,
    params,
    rootElement,
  };
  if (IS_TEST || !IS_PRODUCTION) {
    loadComponentParams.registryUrl =
      'https://www.skyscanner.net/g/oc-registry';
  }

  try {
    await loadComponent(loadComponentParams);
  } catch (err) {
    if (!(err instanceof Error)) return;
    logger.logError({
      message: `Login Modal OC failed to load: ${err}\n${err.stack}`,
      component: namespace,
    });

    try {
      // Test another fetch and log
      const scndresponse = await fetch('/g/oc-registry');

      logger.logError({
        message: `Successful test fetch after Login Modal OC failed to load:\n${scndresponse}`,
        component: namespace,
      });
    } catch (err2) {
      if (!(err2 instanceof Error)) return;
      logger.logError({
        message: `FAILED test fetch after Login Modal OC failed to load: ${err2}\n${err2.stack}`,
        component: namespace,
      });
    }
  }
};

const loginModalInit = (
  experimentParams = {},
  applyLoggedInState = () => {},
) => {
  observer.subscribe('sid-login-success', applyLoggedInState);
  observer.subscribe(
    'sid-login-button-clicked',
    (context: LoginButtonClickedEvent) => {
      observer.publish('sid-login-modal-open');
      if (!loginModalLoaded) {
        const { locale, loginModalVersion, market, ...optionalParams } =
          context;
        loadLoginModal(loginModalVersion, {
          locale,
          market,
          ...experimentParams,
          ...optionalParams,
        });

        loginModalLoaded = true;
      }
    },
  );
};

export default loginModalInit;
