import { useEffect, useState } from 'react';

import {
  fetchCultureSelectorData,
  useCultureSelectorData,
} from '@skyscanner-internal/global-components-8/culture-selector/helpers';
import Header from '@skyscanner-internal/global-components-8/header';
import { SubscriptionClient } from '@skyscanner-internal/global-components-8/login-form/helpers';

import config from '../../config';

import type { HeaderProps } from '@skyscanner-internal/global-components-8/header';

const HeaderWrapper = (existingProps: HeaderProps) => {
  const [authClient, setAuthClient] = useState(null);
  const newProps = { ...existingProps };
  const cultureSelectorContext = useCultureSelectorData(
    fetchCultureSelectorData,
    existingProps.locale || 'en-GB',
    config.servePreReleasedLocales,
  );

  if (newProps.cultureSelectorProps) {
    newProps.cultureSelectorProps.currencies =
      cultureSelectorContext.currencies;
    newProps.cultureSelectorProps.markets = cultureSelectorContext.markets;
    newProps.cultureSelectorProps.locales = cultureSelectorContext.locales;
    newProps.cultureSelectorProps.enMarkets = cultureSelectorContext.enMarkets;
  }

  if (typeof window !== 'undefined') {
    /* Load Auth Client here because we want to check it is not server side this
       code will run only in the browser.
     */
    const loadAuthClient = async () => {
      const { default: AuthClient } = await import(
        /* webpackChunkName: "AuthClient" */ '@skyscanner/auth-client'
      );
      return AuthClient;
    };
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      if (newProps.unstable__LoginFormProps) {
        loadAuthClient().then((AuthClient) => {
          // Use the Auth Client class here to init new instance
          setAuthClient(
            new AuthClient({
              market: newProps.market,
              locale: newProps.locale,
              env: newProps.isProductionEnv ? 'production' : 'development',
              authConsumer: 'header',
              strings: {
                ERROR_MESSAGE_GENERIC: 'Something went wrong',
                LOGIN_SUCCESS_TOAST: 'Login success!',
                INVALID_GRANT: 'Wrong email or password',
                ACCESS_DENIED:
                  'This email address/password are not linked to any account. Please try again.',
              },
            }),
          );
        });
      }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
  }

  if (newProps.unstable__LoginFormProps) {
    newProps.unstable__LoginFormProps.additionalConsentStringKeys = [
      'PRIVACY_POLICY_CONSENT',
      'AGE_RESTRICTION_CONSENT',
    ];
    newProps.unstable__LoginFormProps.subscriptionClient =
      new SubscriptionClient();
    if (authClient) {
      newProps.unstable__LoginFormProps.authClient = authClient;
    }
  }

  return (
    <Header
      {...newProps}
      isFetchingCultureSelectorData={
        cultureSelectorContext.isFetchingCultureSelectorData
      }
      onCultureSelectorButtonClick={
        cultureSelectorContext.onCultureSelectorButtonClick
      }
    />
  );
};

export default HeaderWrapper;
