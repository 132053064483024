import { parse } from 'url';

/**
 * Parses url and returns state object
 * @param {string} url - Full URL including protocol.
 * @param {boolean} pkg - option to use parent package.json to parameterize state
 * @param {object} initialState - initial state to be merged in with URL state
 * @returns {object} State
 */
function initialiseState(url, pkg, initialState) {
  if (process.env.NODE_ENV === 'development') {
    const parsedUrl = parse(url, true);
    const debugState = { debug: true };
    const state = { ...parsedUrl.query };
    if (pkg) {
      const pkgJson = require('../../../package.json'); // eslint-disable-line global-require,import/no-unresolved
      if (pkgJson.oc) {
        const parameters = pkgJson.oc.parameters || {};
        Object.keys(state).forEach((key) => {
          const value = state[key];
          if (key in parameters) {
            const { type } = parameters[key];
            if (type === 'boolean') {
              state[key] = value.toLowerCase() === 'true';
            } else if (type === 'number') {
              state[key] = Number(value);
            } else {
              state[key] = value;
            }
          }
        });
      }
    }
    const finalState = Object.assign(debugState, initialState, state);
    return finalState;
  }
  return initialState || {};
}

export default initialiseState;
